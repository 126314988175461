import styled from "styled-components";

export type THWeight = "bold" | "normal" | "light";

const $H = styled.h1<{
  color: string;
  fontWeight: THWeight;
  margin: string;
}>`
    color: ${(props) => props.theme.colors[props.color] || props.color};
    font-weight: ${(props) =>
            props.fontWeight === "light" ? "lighter" : props.fontWeight};
    width: fit-content;
    line-height: 150%;
    font-family: Open Sans;
    margin: ${(props) => props.margin};
`;

export const $H1 = styled($H)`
    font-size: 36px;
`;
export const $H2 = styled($H)`
    font-size: 32px;
`;
export const $H3 = styled($H)`
    font-size: 24px;
`;
export const $H4 = styled($H)`
    font-size: 18px;
`;
